@import "src/App.scss";

.calendar-container{
    padding: 15px;
    position: relative;
    @include breakpoint(xs){
        height: 100%;
        padding: 75px 10px;
        .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr{
            width: 100%;
            margin: 30px 10px;
            zoom: 0.3;
            display: flex;
            flex-direction: row-reverse;
            flex-wrap: wrap;
            .fc-toolbar-chunk{
                width: 100%;
                font-size: 40px;
                text-align: center;
                color: var(--dark-blue);
                @include breakpoint(xs){
                    width: 100%;
                    margin-bottom: 40px;
                    h2{
                        font-size: 140px;
                    }
                    &:first-child{
                        order: 1;
                        width: fit-content;
                    }
                    &:last-child{
                        order: 0;
                        width: fit-content;
                    }
                }
            }
        }
        .fc-view-harness{
            height: 100vh !important;
        }
    }
    .showAllOrdersButton{
        position: absolute;
        left: 300px;
        top: 50px;
        font-size: 16px;
        background-color: var(--dark-blue);
        padding: 5px 15px;
        border-radius: 5px;
        cursor: pointer;
        color: white;
        p{
            margin: 0;
        }
    }
    .showYearlyProductionButton{
        width: fit-content;
        cursor: pointer;
        position: absolute;
        top: 15px;
        background-color: #2C3E50;
        border-radius: 5px;
        transition: 200ms;
        display: flex;
        input[type=month]{
            width: 100%;
            height: 40px;
            border: solid 1px var(--dark);
            padding: 0 10px;
            font-size: 16px;
            box-sizing: border-box;
            border-radius: 4px;
            color: #2C3E50;
        }
        .filterMonths{
            height: 40px;
            padding: 0 20px;
            background-color: #2C3E50;
            border: solid 1px #2C3E50;
            font-weight: 500;
            color: white;
            border-radius: 0 8px 8px 0;
        }
        @include breakpoint(xs){
            //display: none;
            top: unset;
            position: relative;
            left: unset !important;
            display: flex;
            flex-direction: column;
            width: 100%;
        }
        &:hover{
            background-color: #272F3AFF;
        }
        &.show{
            left: 15px;
        }
        &.hide{
            left: 200px;
        }
        p{
            margin: 0;
            padding: 7px;
            width: fit-content;
            font-weight: 500;
            color: white;
        }
    }
    .selectDepartmentButton{
        width: 230px;
        cursor: pointer;
        position: absolute;
        top: 15px;
        right: 120px;
        transition: 200ms;
        @include breakpoint(xs){
            right: 10px;
        }
        &.show{
            right: 15px;
        }
        .selected-value-container{
            padding: 8px 10px;
        }
    }
}
    .calendar-wrapper{
        margin-top: 50px;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        gap: 20px;
        @include breakpoint(xs){
            margin-top: 10px;
        }
        .calendar-holder{
            width: 24%;
            @include breakpoint(xs){
                width: 100%;
            }
            .fc.fc-media-screen.fc-direction-ltr{
                width: 100%;
                margin: 30px 10px;
                height: 1200px;
                zoom: 0.3;
                pointer-events: none;
                .fc-toolbar-chunk{
                    width: 100%;
                    font-size: 40px;
                    text-align: center;
                    color: var(--dark-blue);
                    @include breakpoint(xs){
                        width: 100%;
                        .fc-toolbar-title{
                            font-size: 190px;
                        }
                        &:first-child{
                            order: 0;
                        }
                        &:last-child{
                            order: 1;
                        }
                    }
                    &:first-child , &:last-child{
                        display: none;
                    }
                }
            }
        }
    }
.fc-daygrid-event{
    border-radius: 10px;
    color: #155724;
    border-color: #FAFAFA;
    border-width: 1px;
    padding: 0 5px;
    overflow: hidden;
    position: relative;
    .fc-event-main{
        width: 100%;
        position: static;
    }
    .event-container{
        &.notDone{
            //pointer-events: none;
            .color-container{
                .colorContainter{
                    background-color: rgb(121, 121, 121) !important;
                }
            }
        }
        p{
            position: relative;
            margin-right: 10px;
            margin-bottom: 0;
            width: fit-content;
            font-weight: 400;
            text-transform: capitalize;
            z-index: 2;
            &.client-name{
                font-weight: 600;
                font-size: 12px;
            }
        }
        .color-container{
            width: 100%;
            height: 100%;
            position: absolute;
            opacity: 1;
            top: 0;
            left: 0;
            display: flex;
            justify-content: space-between;
            z-index: 1;
            .colorContainter{
                //pointer-events: none;
                position: relative;
                z-index: 1 !important;
            }
        }
        .bg-yellow {
            color: var(--dark-blue);
        }
    }
}
.legend{
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    @include breakpoint(xs){
        flex-direction: column;
    }
    div{
        @include breakpoint(xs){
         width: 100%;
        }
        display: flex;
        align-items: center;
        .legendIcon{
            width: 20px;
            height: 20px;
            border-radius: 5px;
            margin: 10px;
            &.completed{
                background-color: var(--green);
            }
            &.toDo{
                background-color: var(--blue);
            }
            &.late{
                background-color: var(--red);
            }
            &.inProcess{
                background-color: var(--yellow);
            }
            &.paused{
                background-color: var(--orange);
            }
        }
        p{
            margin: 0;
        }
    }
}
.fc-scroller{
    overflow: unset !important;
    height: fit-content !important;
}
.fc .fc-scroller-harness{
    overflow: hidden auto !important;
}
.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard{
    height: 98vh;
}
.updateDatesModalContainer{
    position: fixed;
    z-index: 11111;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    .updateDatesModal{
        width: 35%;
        height: fit-content;
        background-color: white;
        position: relative;
        padding: 20px;
        border-radius: 10px;
        @include breakpoint(xs){
            width: 95%;
        }
        .x-sign{
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
            img{
                transform: rotate(-45deg);
            }
        }
    }
}