.landingPage{
  display: flex;
  justify-content: center;
  align-items: center;
  .landingPageWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px;
    img{
      width: 100%;
    }
    input{
      text-align: center;
      width: 100%;
      border: solid 1px var(--light-blue);
      height: 50px;
      border-radius: 8px;
      font-size: 24px;
    }
  }
}