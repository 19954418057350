@import "App";
.startEndInstallationInputs{
  .clearDate{
    top: 50%;
  }
  .pinLogoForMeasure{
    height: calc(100% - 26px);
    bottom: 1px;
  }
}
.file-input-wrapper{
  padding: 0 10px;
}

.installationContainer{
  &.w-100.mb-1{
    @include breakpoint(xs){
      padding-bottom: 150px;
    }
  }
  .mapContainer{
    width: 100%;
    height: 50vh;
    overflow: hidden;
    .map{
      width: 100%;
      height: 100%;
    }
  }
  .leaflet-control-attribution{
    display: none;
  }
}

.allInstallationFiles{
  max-height: 200px;
  overflow: auto;
  margin-top: 60px;
}
.projectInfoContainer{
  background-color: #D4E2F9;
  padding: 10px 5px;
  border-radius: 8px;
  transition: 200ms;
  position: relative;
  .installationMapIcon{
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 15px;
    svg{
      width: 25px;
      height: 25px;
    }
  }
  &.mapOpened{
    border-radius: 0 0 8px 8px;
  }
  color: var(--dark-blue);
  h1{
    font-size: 28px;
  }
  p{
    margin: 0;
  }
}
.conditionalInstallation{
  padding: 10px;
  width: fit-content;
  input{
    margin: 5px;
    width: 20px;
    height: 20px;
  }
}
.measureFiles{
  overflow: hidden;
}