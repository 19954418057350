@import '../../../styles/config.scss';

.inputText {
    display: block;
    width: calc(100% - 40px);
    border: none;
    padding: 15px 20px;
}

.inputText:focus {
    outline: none;
}

/* Material text input */
.textMaterial {
    padding: 5px 0;
    display: block;
    width: 100%;
    border: none;
    background: transparent;
    color: var(--dark-blue);
    font-weight: 500;
    height: 50px;
    font-size: 16px;
    letter-spacing: -0.2px;
    line-height: 16px;
    transition: border-bottom linear 0.2s;
    border-bottom: 2px solid #4d4f5c80;

    &:focus,
    &:hover {
        border-bottom: 2px solid var(--dark-blue);
    }

    &.hasValue {
        color: var(--dark-blue);
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.textMaterial + .label {
    position: absolute;
    pointer-events: none;
    padding: 0;
    top: 19px;
    left: 0;
    // opacity: 0.8;
    transition: 200ms ease all;
    color:#34366dcc;
    height: 17px;
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
}

.textMaterial.hasValue ~ .label,
.textMaterial:focus ~ .label {
    top: -4px;
    left: 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
}

::placeholder {
    color: var(--main);
}

.inputContainer {

    width: 100%;
    position: relative;

    svg {
        position: absolute;
        top: 15px;
        right: 0;
    }
}

.simpleInput {
    padding: 5px 30px 5px 12px;
    display: block;
    width: 100%;
    border: none;
    background: transparent;
    color: var(--main);
    font-weight: 500;
    height: 50px;
    font-size: 16px;
    letter-spacing: -0.2px;
    line-height: 16px;
    transition: border-bottom linear 0.2s;
    border: 1px solid var(--light-blue);
    border-radius: 10px;
    // background: url('../../../assets/icons/pencil.svg');
    background-repeat: no-repeat;
    background-position: 96% center;

    &:focus,
    &:hover {
        box-shadow: 0px 0px 5px 1px rgba(165,201,255,1);
        -webkit-box-shadow: 0px 0px 5px 1px rgba(165,201,255,1);
        -moz-box-shadow: 0px 0px 5px 1px rgba(165,201,255,1);
    }

    &.hasValue {
        color: var(--main);
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &+ .label {
        position: absolute;
        pointer-events: none;
        padding: 0;
        top: 18px;
        left: 13px;
        // opacity: 0.8;
        transition: 200ms ease all;
        color: var(--main);
        height: 17px;
        font-size: 16px;
        font-weight: 500;
        line-height: 17px;
    }
}

.simpleInput.hasValue ~ .label {
    display: none;
}
