@import '../../styles/config.scss';
#root {
    .button {
        display: inline-block;
        position: relative;
        text-align: center;
        font-weight: 400;
        // width: 100%;
        margin: 0;
        // padding: 0;
        border: none;
        background-color: transparent;
        text-decoration: none;
        cursor: pointer;
        overflow: hidden;
        margin-top: 15px;
        transition: background-color .2s linear;
        border-radius: 10px;


        &:hover {
            text-decoration: none;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }

    .button:focus,
    .button:focus {
        outline: 0;
    }

    .modalNavigationContainer {
        .button.bPill {
            line-height: 15px;
        }
    }
    /* Buttons */
    .button.grey-button {
        color: var(--dark);
        background-color: var(--button-grey);
        font-size: 18px;
        font-weight: 700;
        padding: 15px 20px;
        min-width: 200px;
        border: 1px solid var(--light-blue);
        text-transform: uppercase;
        
        &:hover {
            background-color: var(--dark-blue);
            color: #fff;
        }
        
        &:focus {
            // border: 2px solid #141631;
        }
        
        &:disabled {
            background-color: var(--light-grey) !important;
            border: 2px solid var(--light-grey);
            color: white;
            cursor: not-allowed;
        }
        
        @media screen and (max-width:1440px) {
            font-size: 18px;
        }
    }
    
    .button.purple-button {
        color: #fff;
        background-color: var(--dark);
        font-size: 18px;
        font-weight: 700;
        padding: 17px 20px;
        min-width: 200px;
        text-transform: uppercase;
        
        &:hover {
            background-color: var(--dark-blue);
            color: #fff;
        }
        
        &:focus {
            // border: 2px solid #141631;
        }
        
        &:disabled {
            background-color: var(--light-grey) !important;
            border: 2px solid var(--light-grey);
            color: white;
            cursor: not-allowed;
        }
        
        @media screen and (max-width:1440px) {
            font-size: 18px;
        }
    }
    
    .button.danger {
        color: #fff;
        background-color: var(--red);
        font-size: 18px;
        font-weight: 700;
        padding: 15px 20px;
        min-width: 200px;
        text-transform: uppercase;
        
        &:hover {
            background-color: var(--dark-blue);
            color: #fff;
        }
        
        &:focus {
            // border: 2px solid #141631;
        }
        
        &:disabled {
            background-color: var(--light-grey) !important;
            border: 2px solid var(--light-grey);
            color: white;
            cursor: not-allowed;
        }
        
        @media screen and (max-width:1440px) {
            font-size: 18px;
        }
    }
    
    .button.available {
        color: #fff;
        background-color: var(--green);
        font-size: 18px;
        font-weight: 700;
        padding: 15px 20px;
        min-width: 200px;
        text-transform: uppercase;
        
        &:hover {
            background-color: var(--dark-blue);
            color: #fff;
        }
        
        &:focus {
            // border: 2px solid #141631;
        }
        
        &:disabled {
            background-color: var(--light-grey) !important;
            border: 2px solid var(--light-grey);
            color: white;
            cursor: not-allowed;
        }
        
        @media screen and (max-width:1440px) {
            font-size: 18px;
        }
    }
    
    .button.unavailable {
        color: #fff;
        background-color: var(--yellow);
        font-size: 18px;
        font-weight: 700;
        padding: 15px 20px;
        min-width: 200px;
        text-transform: uppercase;
        
        &:hover {
            background-color: var(--dark-blue);
            color: #fff;
        }
        
        &:focus {
            // border: 2px solid #141631;
        }
        
        &:disabled {
            background-color: var(--light-grey) !important;
            border: 2px solid var(--light-grey);
            color: white;
            cursor: not-allowed;
        }
        
        @media screen and (max-width:1440px) {
            font-size: 18px;
        }
    }
    
    .button.medium {
        padding: 0 30px;
        max-height: 40px;
        height: 40px;
        
        &:not(.withSpinner) {
            line-height: 34px;
        }
        @media (max-width:768px) {
            padding:0 20px;
            font-size: 14px;
            max-height: 32px;
            line-height: 28px!important;
        }   
        // width: 270px;
    }
    
    /* Link */
    .basic-link,
    a {    
        color: var(--dark-blue);
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.4px;
        line-height: 1.5em;
        transition: color linear 0.2s;
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
        
        &:hover {
            // opacity: 0.7;
            color: #141631;
        }
        
        &:disabled {
            color: var(--light-grey);
        }
        
    }
    
    button {
        transition: color linear 0.2s;
    }
    
    .admin-page-container {
        .units {
            .button.grey-button {
                margin-top: 20px;
            }
        }
    }
}