@import '../../App.scss';

.measures {
  .measure-form {
    margin: 40px auto 0;

    .product-pic {
      width: 20px;
      height: 20px;
      margin-right: 20px;
    }

    .link-pics {
      color: var(--main) !important;
      text-decoration: none;
    }

    .file-input {
      &.upload-file {
        width: 200px;
        border: 0;
        background-color: transparent;
        text-align: left;
        padding-right: 20px;

        .add-icon {
          margin-left: 20px;
          margin-top: -3px;
        }
      }

      label {
        &:nth-child(2) {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }
    }

    .pr-15 {
      padding-right: 15px !important;
    }

    .pl-15 {
      padding-left: 15px !important;
    }
  }

  .measure-form-wrapper {
    .suggestedClients-container-measures{
      padding-top: 10px;
      background-color: white;
      position: absolute;
      top: calc(100% - 40px);
      left: 0;
      z-index: 199;
      width: 100%;
      height: max-content;
      max-height: 300px;
      overflow-y: auto;
      border: solid 1px var(--light-blue);
      border-radius: 0 0 10px 10px;
      .suggestedClients{
        padding: 10px 15px;
        font-size: 15px;
        &:hover , &.selectedOption{
          background-color: var(--light-blue);
        }
      }
    }
    .date-placeholder {
      padding-left: 10px;
      transition: 200ms;
      font-weight: 500;
      color: var(--dark-blue);

    }

    .product-measure-photos {
      font-size: 18px;
      font-weight: 500;
      color: var(--dark-blue);
    }
    .pinLogoForMeasure {
      height: calc(100% - 36px);
    }
    .calendarIconForMeasure{

      @include breakpoint(xs){
        right: 0;
      }
      right: 13px;
      background-color: var(--light-blue);
      padding: 14px;
      & + p {
        margin: 0;
        left: 5px;
        font-weight: 500;
        color: var(--dark-blue);
      }
    }

    .buttonMobileWidth{
      @include breakpoint(sm){
        width: 50% !important;
      }
      @include breakpoint(xs){
        width: 100% !important;
      }
    }
  }
  .nameSearch{
    position: relative;
  }
  .optimalMeasuringDate{
    position: absolute;
    bottom: -10%;
    left: 5px;
  }

  .tablet-form{
    @media (min-width: 768px) and (max-width: 920px) {
      flex-direction: column;
    } 

    @include breakpoint(xs){
      flex-direction: column;
    }
  }

  .small-tablet{
    @media (min-width: 768px) and (max-width: 920px) {
      width: 100% !important;
    } 

    @include breakpoint(xs){
      flex-direction: column;
      width: 100% !important;
    }

    .mobileWidth{
      @include breakpoint(xs){
        width: 100% !important;
        padding: 0 !important;
      }
    }
  }

  .flex-start-tablet{
    @media (min-width: 768px) and (max-width: 920px) {
      justify-content: flex-start !important;
    } 
  }
}

.mobileMargin{
  @include breakpoint(xs){
    margin: 5px 0;
  }
}

.mobileAlignment{
  @include breakpoint(xs){
    align-items: center !important;
    flex-wrap: wrap;
    gap: 20px;
  }

  .mobileWidth{
    @include breakpoint(xs){
      width: 100% !important;
      padding: 0 !important;
    }
  }
}

.fileMobileAlignment{
  @include breakpoint(xs){
    flex-direction: column;
  }

  .mobileWidth{
    @include breakpoint(xs){
      width: 100% !important;
    }
  }

  .mobileWidthAndAlignment{
    @include breakpoint(xs){
      width: 100% !important;
      flex-direction: column;
      margin: 20px 0;
    }
  }
}

.measureImagesContainer{
  #measureImages{
    display: none;
  }
  .measureImagesImgIndicator{
    position: absolute;
    top: -50%;
    right: 0;
    margin: 0;
    border: solid;
    width: 25px;
    height: 25px;
    text-align: center;
    border-radius: 50%;
    color: white;
    background: var(--dark-blue);
    font-size: 14px;
  }
}
.assetContainer{
  display: flex;
}