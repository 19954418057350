@import '../../../styles/config.scss';

.inputFileWrapper {
    width: 100%;
    // height: 48px;
    margin-bottom: 0;
    display: flex;
    //line-height: 48px;
    //vertical-align: middle;
    //padding: 16px;
    background-color: var(--grey-light);
    cursor: pointer;

    .inputFileButton {
        display: block;
        color: var(--dark);
        display: inline-block;
        width: 116px;
        font-weight: bold;
        font-size: 12px;
        line-height: 27px;
        text-transform: uppercase;
        // height: 48px;
        text-align: center;
        margin-left: 16px;
    }

    .inputFileName {
        display: block;
        color: var(--grey);
        width: 232px;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.2px;
        margin-left: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .inputFile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    .inputFile + div {
        opacity: 0;
        position: absolute;
        top: -7px;
        left: -7px;
        width: calc(100% + 14px);
        height: calc(100% + 14px);
        //border: 2px solid var(--dark);
        border-radius: 15px;
        //box-shadow: 0px 0px 0px 4px rgba(38, 50, 56, 0.1);
        pointer-events: none;
    }

    .inputFile:focus + div {
        opacity: 1;
    }

    .inputFile:focus {
        outline: none;
    }

    .hasFile {
        color: var(--main);
    }

}


