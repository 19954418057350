@import "../../../App.scss";

.calculate-offer-modal-background{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #7070705d;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: #34366D;
    overflow-y: scroll;
    z-index: 9999;
    @include breakpoint(xs){
        padding: 50px 0;
    }
    .updateStartDateModalContainer{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 99999;
        display: flex;
        justify-content: center;
        align-items: center;
        .updateStartDateModal{
            background-color: white;
            padding: 20px 40px;
            border-radius: 10px;
            width: 30vw;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .simple-date-input{
                width: 49%;
            }
            .saveStartDateButton{
                width: 49%;
                padding: 10px;
                color: white;
            }
        }
    }
    .calculateOfferButton{
        background-color: var(--dark-blue) !important;
        border: solid 1px var(--light-blue) !important;
        width: 130px;
        &.editButton{
            width: 50px;
        }
    }
    .card{
        background-color: #FCFCFC;
        padding: 35px;
        width: fit-content;
        min-height: fit-content;
        margin: auto;
        h1{
            font-size: 18px;
        }
        .list-title{
            margin-top: 0;
            padding-left: 10px;
            position: relative;
            font-weight: bold;
            color: #252434;
            .x-sign{
                width: 20px;
                height: 20px;
            }
            &::before{
                content: '';
                position: absolute;
                background-color: #34366D;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }

        }
    }
    .x-sign{
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 0;
        //transform: translateY(-50%);
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        img{
            transform: rotate(-45deg);
        }
    }
    .modal-title{
        font-size: 22px;
    }
    .print-doc{
        justify-self: end;
        font-weight: bold;
        padding-right: 100px;
        p{
            white-space: nowrap;
        }
    }
    .add-product{
        font-size: 18px !important;
    }
    .input-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .input-wrapper{
            min-width: 250px;
            max-width: 250px;
            &.offerInputWrapper{
                .initialDate{
                    position: absolute;
                    top: 100%;
                    left: 5px;
                    font-size: 14px;
                    @include breakpoint(xs){
                        top: 10px !important;
                        left: unset;
                        right: 0;
                    }
                }
                div{
                    input{
                        border-radius: 0 !important;
                    }
                }
            }
            label{
                margin-block: 10px;
                margin-bottom: 10px;
            }
            input{
                color: #34366D;
            }
        }
    }
    .comment-section{
        .input-wrapper{
            margin-top: 40px;
            input{
                background-image: none;
            }
        }
        .input-wrapper:first-child{
            min-width: 73.5%;
        }
    }
    .product-separation-line{
        width: 80%;
        margin: 25px 0 0 0;
    }
    .button-container{
        button{
            background-color: #252434;
            margin: 10px 0 0 0 !important;
            width: 277px;
            border-radius: 15px;
            border: solid 1px;
            padding: 15px 0;
            color: white;
            transition: 100ms;
            text-transform: uppercase;
            font-weight: bold!important;
            &:hover{
                background-color: var(--dark-blue)
            }
        }
    }
    .print-container{
        #printContainer{
            display: none;
        }
    }
    .printButton{
        border: none;
        background-color: transparent;
        font-size: 18px;
        color: var(--dark-blue);
        img{
            width: 20px;
            height: 20px;
        }
    }
    .designation-select{
        margin-block: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        min-width: 277px;
        .select{
            .selected-value-container{
                min-height: 50px;
                padding-left: 10px;
            }
        }
    }
    .calculateOfferDateInput{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        z-index: 11;
        .react-date-picker{
            width: 100%;
            padding-inline: 10px;
            color: var(--main);
            font-weight: 500;
            height: 50px;
            font-size: 16px;
            letter-spacing: -0.2px;
            line-height: 16px;
            border: 1px solid var(--light-blue);
            border-radius: 10px;
        }
    }
    .create-article-modal{
        width: 100vw;
        height: 90vh;
        position: fixed;
        box-shadow:0px 0px 50px rgba(0, 0, 0, 0.295);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999999;
        overflow-y: auto;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        .d-flex.justify-content-between.align-items-center.w-100{
            margin-top: 30px;
        }
        .input-wrapper{
            min-width: unset !important;
            max-width: unset !important;
        }
        .select-wrapper{
            margin: 1rem 0 0 0 !important;
            width: 400px;
        }
    }
    .product-wrapper{
        position: relative;
        .forProductionInnerStockCheck{
            position: absolute;
            top: 5px;
            right: 80px;
        }

        .round-checkbox {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid #34366D;
            display: inline-block;
            position: relative;
            overflow: hidden;
        }

        /* Style for the checkbox input */
        .round-checkbox input {
            opacity: 0;
            position: absolute;
            width: 120%;
            height: 120%;
            cursor: pointer;
            transition: 100ms;
            transform: translate(-5%, -5%);
        }

        /* Style for the checked state */
        .round-checkbox input:checked {
            background-color: #34366D;
            opacity: 1;
            accent-color: #34366D;
        }
        .x-sign{
            top: 0;
            span{
                font-size: 24px;
                color: salmon;
            }
        }
        .canceledLabel{
            position: absolute;
            top: 10px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 12;
            p{
                background-color: salmon;
                color: white;
                border-radius: 8px;
                padding: 5px 10px;
            }
        }
        .cancel-sign{
            width: 20px;
            height: 20px;
            position: absolute;
            top: 3px;
            right: 43px;
            img{
                width: 100%;
                transform: rotate(45deg);
            }
        }
        .input-container{
            // height: 110px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-end;
            .input-wrapper{
                min-width: 140px;
                max-width: 140px;
                margin: 0 !important;
                label{
                    margin-bottom: 5px;
                }
                input{
                    color: #34366D;
                    padding-left: 10px;
                }
            }
            .select-wrapper{
                min-width: 170px;
                max-width: 170px;
                margin-bottom: 0;
                label{
                    margin-block: 10px;
                    margin-bottom: 10px;
                }
                input{
                    color: #34366D;
                }
            }
            .smallerInput{
                input{
                    padding: 0 5px 0 10px !important;
                }
                &.taxPrice{
                    min-width: 150px;
                    max-width: 150px ;
                    width: 150px;
                }
                min-width: 150px ;
                max-width: 150px;
                width: 150px;
                &.discountInput{
                    position: relative;
                    .discountValue{
                        position: absolute;
                        top: 100%;
                    }
                }
            }
        }
    }
}


// 

.calculate-offer-modal-background .input-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline !important;
}


.mikaTextInputWrapper input { 
    padding: 12px;
}

.product-wrapper{ 
    .input-container{
        .mikaTextInputWrapper.bordered.col-1 {
            margin-bottom: 0;
        }
    } 
}

*:focus {
    outline:none !important;
}

@media screen and (max-width: 500px) {
    .offerInputWrapper{
        margin: 20px 0 !important;
    }
    .discountedTotalWrapper{
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 40px;
        .discountElement{
            padding: 10px 0 !important;
            width: 100% !important;
            .input-wrapper{
                margin: 0 !important;
            }
            .clearValueIcon{
                top: 30% !important;
            }
        }
    }
    .discountValue{
        top: 10px !important;
        left: unset !important;
        right: 0;
    }
    .calculate-offer-modal-background{

        .updateStartDateModalContainer{

            .updateStartDateModal{

                .saveStartDateButton{

                }
            }
        }
        .calculateOfferButton{

        }
        .card{
            width: 105vw !important;
            padding: 20px;
            .modal-title{
                display: none;
            }
            .list-title{
                display: none;
            }
        }
        .add-product{
            font-size: 18px !important;
        }
        .input-container{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .input-wrapper{
                min-width: unset !important;
                max-width: unset !important;
                width: 100%;
                margin: 0 !important;
                label{
                    margin-block: 10px;
                    margin-bottom: 10px;
                }
                input{
                    color: #34366D;
                }
            }
        }
        .comment-section{
            .input-wrapper{
                margin-top: 40px;
                input{
                    background-image: none;
                }
            }
            .input-wrapper:first-child{
                min-width: 73.5%;
            }
        }
        .product-separation-line{
            width: 95%;
        }
        .select-wrapper.designation-select.max-width-input.mobile{
            min-width: 100% !important;
            max-width: 100% !important;
        }
        .calculateOfferDateInput{
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            z-index: 11;
            .react-date-picker{
                width: 100%;
                padding-inline: 10px;
                color: var(--main);
                font-weight: 500;
                height: 50px;
                font-size: 16px;
                letter-spacing: -0.2px;
                line-height: 16px;
                border: 1px solid var(--light-blue);
                border-radius: 10px;
            }
        }
        .endDate{
            pointer-events: none !important;
            input {
                background: url('../../../assets/icons/calendar.svg');
                background-repeat: no-repeat;
                background-position: center right 6px;
            }
        }
        .create-article-modal{
            width: 100vw;
            height: 90vh;
            position: fixed;
            box-shadow:0px 0px 50px rgba(0, 0, 0, 0.295);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 999999;
            overflow-y: auto;
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            .d-flex.justify-content-between.align-items-center.w-100{
                margin-top: 30px;
            }
            .input-wrapper{
                min-width: unset !important;
                max-width: unset !important;
            }
            .select-wrapper{
                margin: 1rem 0 0 0 !important;
                width: 400px;
            }
        }
        .product-wrapper{
            position: relative;
            .x-sign{
                top: 0;
            }
            .input-container .smallerWidth.mobile, .mobile, .input-wrapper.smallerInput.medium{
                min-width: 100% !important;
                max-width: 100% !important;
            }

            .input-container{
                // height: 110px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: flex-end;
                .input-wrapper{
                    min-width: 140px;
                    max-width: 140px;
                    margin: 0 !important;
                    label{
                        margin-bottom: 5px;
                    }
                    input{
                        color: #34366D;
                        padding-left: 10px;
                    }
                }
                .select-wrapper{
                    min-width: 170px;
                    max-width: 170px;
                    margin-bottom: 0;
                    label{
                        margin-block: 10px;
                        margin-bottom: 10px;
                    }
                    input{
                        color: #34366D;
                    }
                }
                .smallerInput{
                    input{
                        padding: 0 5px 0 10px !important;
                    }
                    &.taxPrice{
                        min-width: 150px;
                        max-width: 150px ;
                        width: 150px;
                    }
                    min-width: 150px ;
                    max-width: 150px;
                    width: 150px;
                }
            }
        }
    }

    .calculate-offer-modal-background .input-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: baseline !important;
    }

    .mikaTextInputWrapper input {
        padding: 12px;
    }

    .product-wrapper{
        .input-container{
            .mikaTextInputWrapper.bordered.col-1 {
                margin-bottom: 0;
            }
        }
    }
}
.discountedTotalWrapper{
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    .discountElement{
        width: 30%;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: end;
        .input-wrapper{
            margin: 0 !important;
        }
        .clearValueIcon{
            top: 30% !important;
        }
    }
    .discountValue{
        position: absolute;
        top: 100%;
        left: 0;
    }
}
