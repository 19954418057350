@import './styles/index.scss';
@import '~rc-tooltip/assets/bootstrap_white.css';

@mixin breakpoint($class){
    @if $class == xs{
      @media (min-width: 320px) and (max-width: 525px){
         @content;
      }
    }@else if $class == sm{
      @media (min-width: 768px) and (max-width: 991px){
        @content;
      }
    }@else if $class == md{
      @media (min-width: 992px) and (max-width: 1200px){
        @content;
      }
    }@else if $class == lg{
      @media  (min-width: 1200px) and (max-width: 1499px){
        @content;
      }
    }@else if $class == xl{
      @media (min-width: 1500px){
        @content;
      }
    }@else{
      @warn "Breakpoint mixin supports: xs, sm, md, lg xl";
    }
  }