.container-fluid {
    padding-left: 0!important;
    .loginContainer{
        display: flex;
    }
    .left-side {
        padding: 0;
        width: 50%;
        .login-bg {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .right-side {
        padding: 0;
        width: 50%;
        .login-logo {
            width: 240px;
            margin: 30px auto;
            cursor: pointer;
        }
        .terms-policy {
            position: absolute;
            bottom: 0;
            color: var(--dark-blue);
        }
        .login-text {
            color: #4d4f5c80;
        }
        .go-to-login {
            a {
                font-size: 16px!important;
                text-transform: initial!important;
                text-decoration: none;
            }
        }
        .checkbox-input {
            margin: 15px 0;
        }
        .input-wrapper {
            &:nth-child(2) {
                margin-bottom: 10px;
            }
        }
        .button.forgot-password {
            margin-top: 17px!important;
            font-size: 14px!important;
            height: 30px;
            font-weight: normal!important;
            text-transform: capitalize!important;
        }
    }
}
@media screen and (max-width: 500px){
    .container-fluid{
        padding: 0 !important;
    }
    .loginContainer{
        flex-direction: column;
        .left-side{
            display: none;
        }
        .right-side{
            width: 100%;
        }
    }
}