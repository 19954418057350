#printContainer{
    width: 100%;
    height: fit-content;
    padding: 20px;
    .logoContainer{
        width: 150px;
        height: auto;
    }
    .contentFieldContainer{
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        hr{
            width: 80%;
            margin: 0 auto;
        }
        .column{
            width: 23%;
            margin-right: 2%;
        }
        label {
            font-size: 14px;
        }
        p {
            border: solid 0.5px var(--dark-blue);
            padding: 10px;
            border-radius: 5px;
            min-height: 50px;
        }
        &.productDetailsContainer {
            display: flex;
            div {
                flex: auto;
            }
        }
    }
    .listTitle{
        margin-top: 20px;
        font-weight: bold;
        color: #34366D;
    }
    .consumatorInfoContainer {
        width: 100%;
        .commentAndTotal{
            width: 100%;
            .commentInput{
                width: 65%;
                margin-right: 5%;
            }
        }
    }
}