@import "App";
.modal-wrapper {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100vh; 
    background-color:#00000085;
    display: flex;
    align-items:center;
    justify-content:center;
    z-index: 999999999;
}

.navbarWrapper{
    @include breakpoint(xs){
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1111111111;
    }
}