@import './config.scss';
@import './typography.scss';
@import './utilities.scss';

body {
    margin: 0;
    padding: 0;
    @media screen and (max-width: 1030px) {
        zoom: 90%;
    }
    overflow: hidden;
    background-color: #FAFAFA;
    input:-internal-autofill-selected {
        color: var(--main)!important;
    }
    .inside-container,
    .inside-container > * {
        height: 100%;
    }
    .z-index-0 {
        z-index: 0;
    }
    .text-center{
        text-align: center!important;
    }
    .text-right{
        text-align: right!important;
    }
    .text-left{
        text-align: left!important;
    }
    .wrapper {
        position: relative;
        padding: 20px;
        height: 100%;
        @media screen and (max-width: 1440px){
            padding: 25px;
            height: 100%;
        }
        @media screen and (max-width: 500px){
            padding: 50px 5px 150px 5px;
            height: 100%;
            .left-side {
                padding: 0;
            }
        }
        .left-side {
            padding-left: 0;
        }
        .right-side {
            padding-right: 0;
        }
        .locationContainer{
            background: none !important;
            label {
                height: 0;
            }
            div span{
                font-size: clamp(13px, 0.8vw, 16px) !important;
            }
        }
        .small-tablet-width{
            @media (min-width: 768px) and (max-width: 920px) {
              width: 250px;
            }
        }
    }
    .pinLogoForMeasure{
        width: 60px;
        height: calc(100% - 40px);
        position: absolute;
        bottom: 4px;
        right: 2px;
        background-color: #EEEEEE;
        padding: 15px;
        z-index: 1;
        border-radius: 0 9px 9px 0;
        cursor: pointer;
        &.transportPinLogo{
            right: 15px;
        }
    }
    .font-weight-bold{
        font-weight: bold;
    }
    input[disabled]{
        background-color: #F3F3F5 !important;
        background-image: none;
        cursor: no-drop;
    }
    .simple-date-input{
        background: none;
        padding: 0 0 0 0 !important;
        position: relative;
        &.pl-15{
            .date-placeholder{
                left: 16px;
            }
        }
        .date-placeholder{
            display: none;
            position: absolute;
            top: 50%;
            left: 2px;
            width: 70%;
            transform: translateY(-50%);
            border-radius: 10px;
            padding-left: 23px;
            background-color: white;
            line-height: 50px;
            z-index: 4;
            pointer-events: none;
            font-weight: 500;
            color: var(--main);
        }
        .react-date-picker{
            width: 100%;
            padding: 0;
            padding-right: 5px;
            padding-left: 12px;
            font-weight: 500;
            height: 48px;
            font-size: 16px;
            line-height: 16px;
            background-color: #fff;
            border: 1px solid var(--light-blue);
            border-radius: 10px;
            z-index: 2;

            input , span{
                color: var(--main);
            }
        }
        &.does-not-have-data{
            .date-placeholder{
                display: block;
            }
        }
        &.does-have-data{
            .react-date-picker{
                // pointer-events: none;
                background-color: #fff;
            }
        }
    }
    .custom-select-container{
        position: relative;
        &::before{
            content: "";
            background-color: var(--main);
            clip-path: polygon(80% 30%,0 30%,40% 80%);
            width: 15px;
            height: 15px;
            display: block;
            position: absolute;
            top: 50%;
            right: 10px;
            -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            pointer-events: none;
        }
        .custom-select{
            background: none;
            position: relative;
            appearance: none;
            option{
                font-size: 20px;
            }
            &:focus-visible{
                outline: none;
            }
        }
    }
    .custom-input-file{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        margin: 4px 0;
        *:focus-within{
            outline: none !important;
        }
        label{
            span:nth-child(1){
                display: none;
                margin: 0;
            }
            span:nth-child(2) {
                font-size: 14px;
                color: var(--dark-blue);
                margin-top: 37px;
                margin-left: 5px;
            }
            div:last-child {
                top: 0!important;
            }
        }
    }
    .create-client-icon {
        cursor: pointer;
        width: 25px;
        margin-left: 25px;
    }
    .pr-15{
        padding-right: 15px !important;
    }
    .pagination{
        padding-top: 10px;
        user-select: none;
        margin: 0;
        padding-bottom: 0;
        li{
            min-width: 30px;
            min-height: 30px;
            margin-inline: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            &.active{
                background-color: var(--dark-blue);
                border-radius: 5px;
                a{
                    color: white !important;
                }
            }
            a{
                color: var(--pagination-gray) !important;
                width: 100%;
                padding-inline: 10px;
            }
        }
        .previous , .next{
            display: flex;
            align-items: center;
            a{
                padding: 0;
                width: fit-content;
            }
            svg{
                fill: var(--dark-blue);
                width: 15px;
                height: 15px;
            }
            &.disabled{
                pointer-events: none;
                svg{
                    fill: var(--pagination-gray);
                }
            }
        }
        .previous{
            justify-content: flex-start;
            svg{
                transform: rotate(90deg);
            }
        }
        .next{
            justify-content: flex-end;
            svg{
                transform: rotate(-90deg);
            }
        }
    }
    .link-pics{
        text-decoration: none;
    }
}
*::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}
*::-webkit-scrollbar-track {
    background: #E3E3E3;
    border-radius: 3px;
}
*::-webkit-scrollbar-thumb {
    background: #C8C7C7;
    border-radius: 3px;
}

.dot{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: auto;
    background-color: var(--dark-blue);
}
.cursor-pointer{
    cursor: pointer;
}
.nameSearch{
    z-index: 200;
}
.suggestedClients-container{
    padding-top: 10px;
    background-color: white;
    position: absolute;
    top: calc(100% - 10px);
    left: 0;
    z-index: 199;
    width: 100%;
    height: max-content;
    max-height: 300px;
    overflow-y: auto;
    border: solid 1px var(--mainColor);
    border-radius: 0 0 10px 10px;
    .suggestedClients{
        padding: 10px 15px;
        font-size: 15px;
        &:hover , &.selectedOption{
            background-color: var(--light-blue);
        }
    }
}
.inputWrapperbackgroundImageNone{
    input{
        background: none !important;
    }
}
.heightFitContent{
    height: fit-content !important;
}
.widthFitContent{
    width: fit-content !important;
}
.pl-12{
    padding-left: 12px !important;
}
.pr-12{
    padding-right: 12px !important;
    &.simple-date-input{
        padding-right: 12px !important;
    }
}
.errorMessage{
    margin: 0;
    position: absolute;
    top: 100%;
    left: 12px;
    color: salmon !important;
    font-size: 12px !important;
    text-transform: capitalize;
}
.flat{
    input, textarea{
        border-bottom: solid 2px var(--dark-blue);
        border-top: none;
        border-left: none;
        border-right: none;
    }
}
.bordered{
    height: fit-content;
    input, textarea{
        border: solid 1px var(--light-blue);
        border-radius: 10px;
    }
    label{
        padding: 5px 10px;
        transition: 200ms;
        font-weight: 500;
        color: var(--dark-blue);
    }
}

.Toastify{
    z-index: 1111111111;
    position: relative;
    .Toastify__toast-container{
        padding-bottom: 40px;
        width: fit-content;
    }
    .Toastify__toast{
        color: #000000;
        align-items: center;
        border-radius: 25px;
        height: 80px;
        width: 450px;
        padding-left: 80px;
        position: relative;
        font-weight: 400;
        font-size: 14px;
        &.Toastify__toast--success{
            background-color: #F4FFF4;
            border: solid 1px #3AC32E;
            &::before{
                background-image: url("../assets/icons/successToastIcon.svg");
            }
        }
        &.Toastify__toast--error{
            background-color: #FFEFEF;
            border: solid 1px #FF0000;
            &::before{
                background-image: url("../assets/icons/errorToastIcon.svg");
            }
        }
        &.Toastify__toast--warning{
            background-color: #FFF8EF;
            border: solid 1px #FF9300;
            &::before{
                background-image: url("../assets/icons/warningToastIcon.svg");
            }
        }
        &.Toastify__toast--info{
            background-color: #EAF6FF;
            border: solid 1px #0061A8;
            &::before{
                background-image: url("../assets/icons/infoToastIcon.svg");
            }
        }
        &::before{
            content: '';
            width: 40px;
            height: 40px;
            position: absolute;
            left: 30px;
            background-size: 100%;
        }
    }
    .Toastify__close-button{
        background: none;
        margin: auto 5px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        &:hover{
            background-color: #ffffff   ;
        }
        svg{
            fill: black;
            width: 20px;
            height: 20px;
        }
    }
}
@media screen and (max-width: 500px) {
    .Toastify__close-button{
        display: none!important;
    }
}
a * {
    cursor: pointer;
}

.statusFilterContainer{
    width: 300px;
    height: 40px;
    color: white;
    position: absolute;
    z-index: 1;
    right: 360px;
    top: 48px;
    transform: translateY(-50%);
    .select{
        .selected-value-container{
            padding-bottom: 5px !important;
            align-items: flex-end !important;
            border-radius: 0px !important;
            border: none !important;
            border-bottom: solid 1px #939393 !important;
        }
        svg{
            margin-bottom: 5px !important;
        }
        .clearValueIcon{
            filter: grayscale(1);
            top: 60% !important;
        }
    }
    @media screen and (max-width:1440px) {
        top: 52px;
    }
}
.MuiTableRow-head{
    th{
        z-index: 0;
    }
}
.height111vh{
    min-height: 100vh;
    height: 100vh;
    background-color: #FAFAFA;
    @media screen and (max-width: 1030px){
        min-height: calc(111vh + 10px);
        height: calc(111vh + 10px);
    }
}
.googleMapsLocationLink{
    top: 102%;
    left: 15px;
    position: absolute;
    font-size: 13px !important;
    text-decoration: none;
    text-transform: capitalize !important;
}
.w-33{
    width: 33%;
}
.fileRemovalButton{
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    img{
        transform: rotate(-45deg);
    }
}

.pointerEventsNone{
    user-select: none;
    pointer-events: none;
}

.clearDate{
    position: absolute;
    z-index: 1;
    right: 70px;
    top: 45px;
    border-radius: 50%;
    background-color: #141631;
    color: white;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    p{
        margin: 0;
        line-height: 25px;
        font-size: 24px;
        transform: rotate(45deg);
    }
}

.hugePaddingBottom{
    padding-bottom: 100vw;
}

@media screen and (max-width: 500px) {
    .Toastify{
        .Toastify__toast{
            width: 350px;
            transform: translateX(60px);
        }
    }
}

.firstColumnThinner{
    .MuiPaper-root{
        .MuiTableHead-root{
            .MuiTableRow-root.MuiTableRow-head{
                .MuiTableCell-root.MuiTableCell-head{
                    padding: 10px;
                    &:first-child{
                        width: 10px !important;
                    }
                }
            }
        }
        .MuiTable-root{
            .MuiTableBody-root{
                .MuiTableRow-root{
                    .MuiTableCell-root{
                        padding: 10px;
                        &:first-child{
                            width: 10px !important;
                        }
                    }
                }
            }
        }
    }
}