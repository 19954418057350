@import '../../../styles/config.scss';

.inputSelectOption {

    &:hover, &:focus, &.active {
        color: var(--main);
        background-color: var(--grey-hover);
    }
}

.whiteOptions {
    .selectOptionsWrapper {
        background-color: white;
    }
}

.dropdown {
    input {
        margin-bottom: 3px;
    }
}
