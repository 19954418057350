@import '../../../styles/config.scss';

.checkboxWrapper {
  list-style: none;
  padding: 0;
}

.checkbox {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  cursor: pointer;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 0 0 25px;
  cursor: pointer;
  width: 100%;
  white-space: pre;

  height: 26px;
  color: var(--dark-blue);
  // color: #37383C;
  font-size: 14px;
  letter-spacing: -0.17px;
    outline: 0;


    &:focus {
        .checkboxMark {
            border: 2px solid #263238 !important;
        }
        .checkboxMark:before {
            opacity: 1;
            border: 2px solid #263238 !important;
        }
    }
    &:hover {
      color: #141631;
    }
}

.checkboxLabel input.checkboxInput {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkboxMark {
  position: absolute;
  // background: url('../../../assets/icons/checkmark-slim.svg') center / contain no-repeat;
  top: 2px;
  left: 0;
	height: 20px;
	width: 20px;
  border: 1px solid var(--dark-blue);
  border-radius: 4px;

    &:before {
        content: "";
        opacity: 0;
        height: 30px;
        width: 30px;
        position: absolute;
        top: -7px;
        left: -7px;
        border-radius: 4px;
        border: 2px solid var(--dark-blue);
        box-shadow: 0px 0px 0px 4px rgba(38, 50, 56, 0.1);
    }
}

.checkboxMark.checked {
    border: 2px solid var(--dark-blue) !important;
}

:global(.filters) {
  .checkboxMark {
    position: absolute;
    // background: url('../../../assets/icons/checkmark-slim.svg') center / contain no-repeat;
    top: 0;
    bottom: 0;
    left: 0;
    height: 20px;
    width: 20px;
    margin: auto;
  }

  .checkboxLabel {
    font-size: 13px;
    flex: 1 50%;
  }

  :global(label.fullWidth) {
    flex: 1 100%;
    margin-top: 19px !important;
    white-space: normal;

    &:first-child {
      margin-top: 10px !important;
    }
  }
}

.checkboxLabel:hover input.checkboxInput ~ .checkboxMark {  }

.checkboxLabel input.checkboxInput:checked ~ .checkboxMark {
  background: url('../../../assets/icons/checkmark-bold.svg') center / contain no-repeat;
  background-size: 10px;
  opacity: 1;
}

.checkboxMark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkboxLabel input.checkboxInput:checked ~ .checkboxMark:after {
  display: block;
}
