@import '../../../styles/config.scss';

.inputLabel {
    display: block;
    width: 100%;
    border: none;
}

.inputLabel {
    // padding: 15px 20px;
}
