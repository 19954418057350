.simpleLoader{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    height: clamp(50px, 80%,  20px);
    animation: roll forwards 900ms infinite;
  }
}

@keyframes roll {
  0%{
    transform: rotate(0);
  }
  100%{
    transform: rotate(360deg);
  }
}