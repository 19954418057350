#root {
    .page404 {
        text-align: center;
        display: flex;
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        align-items: center;
        justify-content: center;
        img{
            width: auto;
            height:  560px;
            margin: 0;
        }
    }
}